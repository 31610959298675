<template>
  <div class="bg">
    <div class="">
      <van-cell is-link title="关于我们" @click="showDialog" />
      <van-cell is-link title="隐私保护政策" @click="showDialog" />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit" @click="onSubmit"> 退出登录 </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      form: {
        nickname: "",
        cover: "",
        sex: "",
        introduce: "",
      },
    };
  },
  methods: {
    onSubmit() {},
    showDialog() {
      Dialog.alert({
        title: "标题",
        message: "弹窗内容",
      }).then(() => {
        // on close
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  .top {
    background: #fff;
    padding: 16px 16px 48px 16px;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
}
</style>
